import { render, staticRenderFns } from "./MoleculeSidebarResults.vue?vue&type=template&id=da7d038c&scoped=true&"
import script from "./MoleculeSidebarResults.vue?vue&type=script&lang=js&"
export * from "./MoleculeSidebarResults.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeSidebarResults.vue?vue&type=style&index=0&id=da7d038c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da7d038c",
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeSidebarResults.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2Fbuilding%2FMoleculeSidebarResults.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingMoleculeSidebarResult: require('/app/components/molecules/project/building/MoleculeSidebarResult.vue').default})
